import React, { useEffect } from 'react'
import CarouselModel from './CarouselModel'
import { useAppSelector } from '../services/store/store'
import { getCloseIcon } from '../utils/icon'
import { Button } from 'antd'
import { trackEvent } from '../utils/tracking'
import useCustomTranslation from '../shared_utils/translation'
import { ApiCustomError, Look, Model } from '../types/api-types'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

interface ModalSwitchModelProps {
    closeModal(): void
    models?: Model[]
    look?: Look
    itemType?: string
    error?: FetchBaseQueryError | SerializedError
}

export default function ModalSwitchModel(props: ModalSwitchModelProps) {
    const { t } = useCustomTranslation()

    const { closeModal, models, look, itemType, error } = props

    const storeConfig = useAppSelector((state) => state.config)
    const hasMultisize = useAppSelector((state) => state.settings.hasMultisize)

    useEffect(() => {
        if (look && itemType && storeConfig.apiConfig && hasMultisize !== undefined) {
            trackEvent('Open Modal', [look, { item_type: itemType.toUpperCase() }], 'Main')
        }
    }, [look, itemType, storeConfig.apiConfig, hasMultisize])

    useEffect(() => {
        // ---- Pour envoyer un close modal avec data, on se base sur l'unmount ----
        return () => {
            if (look && itemType) {
                trackEvent('Close Modal', [look, { item_type: itemType.toUpperCase() }], 'Main')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className='modal--container'
            style={{
                ...(error ? { width: '50vw', height: '50vh' } : null),
            }}
        >
            <div className='modal--header'>
                <h2 className='modal--title'>
                    {t('title')} {models ? `(${models.length})` : ''}
                </h2>
                {
                    <Button
                        className='modal--close'
                        onClick={closeModal}
                        icon={getCloseIcon()}
                        type='link'
                    />
                }
            </div>

            <div className='modal--content'>
                {error && (
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 'bold' }}>{t('error')}</div>
                        <div>{(error as ApiCustomError)?.data?.message}</div>
                    </div>
                )}
                {models && look && itemType && storeConfig && (
                    <CarouselModel
                        models={models}
                        look={look}
                        itemType={itemType}
                        nbMaxCards={storeConfig.apiConfig?.sm_nb_max_cards}
                        enableMoreModels={storeConfig.apiConfig?.enable_more_models}
                    />
                )}
            </div>
        </div>
    )
}
