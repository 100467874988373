import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { store } from '../store/store'
import * as qs from 'qs'
import { RawConfig } from '../store/slice/configSlice'
import { API_CONFIG_KEYS } from '../../utils/constant'

export function getCommonHeaders(): { [key: string]: string } {
    return {
        'Content-Type': 'application/json',
        'Accept-Language': 'fr',
    }
}

export const API_ENDPOINT: string = (
    process.env.API_ENDPOINT ||
    process.env.REACT_APP_API_ENDPOINT ||
    ''
).replace('[QUERY_HOSTNAME]', window.location.hostname)

const getConfigFromStore = () => {
    const configStore = store?.getState().config
    if (!configStore || !configStore.config) {
        return
    }

    return configStore.config
}

export const baseUrl = () => API_ENDPOINT || `https://api.veesual.ai`

export const queryParamSerializer = (param: any) => {
    return qs.stringify(param, { arrayFormat: 'brackets', encode: true })
}

// ---- Build the default params from the params we have in the store ----
export const getDefaultParams = () => {
    const storeConfig = store?.getState().config

    if (!storeConfig || !storeConfig.queryParams) {
        return null
    }

    const payload: RawConfig = {}

    API_CONFIG_KEYS.forEach((apiConfigKey) => {
        if (storeConfig.queryParams && storeConfig.queryParams[apiConfigKey]) {
            payload[apiConfigKey] = storeConfig.queryParams[apiConfigKey]
        }
    })

    return payload
}

// Basic RTK query function to make API calls
export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl(),
    prepareHeaders: (headers) => {
        const storeConfig = getConfigFromStore()
        headers.set('Content-Type', 'application/json')
        headers.set('Accept-Language', 'fr')
        if (storeConfig) {
            headers.set('Authorization', 'Bearer ' + storeConfig['api_key'])
        }

        // ---- If Needed to test on multi env ----
        if (process.env.REACT_APP_MULTIENV) {
            headers.set('X-Test-Env', process.env.REACT_APP_MULTIENV)
        }

        return headers
    },
    headers: getCommonHeaders(),
    paramsSerializer: queryParamSerializer,
})
