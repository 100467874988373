import { ConfigProvider, Modal, ThemeConfig } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ModalSwitchModel from '../components/ModalSwitchModel'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../services/store/store'
import {
    RawConfig,
    addApiConfig,
    addConfig,
    addQueryParams,
    changeModalVisible,
} from '../services/store/slice/configSlice'
import { DEFAULT_NB_MAX_CAROUSEL_CARDS, MODAL_ROOT_ID } from '../utils/constant'
import { useGetConfigQuery } from '../services/api/api-config'
import { CompanyProfile, VeesualEvent } from '../types/api-types'
import { useGetModelsQuery } from '../services/api/api-model'
import i18n from '../i18n/config'
import { disableAnalytics, enableAnalytics } from '../utils/tracking'
import Loader from '../components/Loader'

export default function Main() {
    const dispatch = useDispatch()
    const modalVisible = useAppSelector((state) => state.config.modal.visible)
    const storeConfig = useAppSelector((state) => state.config)
    const storeQueryParams = useAppSelector((state) => state.config.queryParams)
    const htmlOverflowRef: any = useRef()

    const [clicked, setClicked] = useState<boolean>(false)

    const { data: apiConfig, isFetching: isApiConfigFetching } = useGetConfigQuery(undefined, {
        skip: !storeConfig || !storeConfig.queryParams,
    })

    const {
        data: modelsResponse,
        isFetching,
        error: errorModels,
    } = useGetModelsQuery(storeQueryParams, { skip: !storeQueryParams })

    const configTheme = useMemo(() => {
        const primaryColor =
            apiConfig?.custom_colors?.primary_color ||
            storeConfig?.config?.primary_color ||
            '#1677FF'

        const root = document.documentElement
        root?.style.setProperty('--scrollbar-color', primaryColor)
        root?.style.setProperty('--primary-color', primaryColor)

        const theme: ThemeConfig = {
            token: {
                colorPrimary: primaryColor,
                fontFamily: 'inherit',
                colorLink: primaryColor,
                borderRadius: 0,
            },
            components: {
                Alert: {
                    colorInfoBg: primaryColor + '50',
                    colorInfo: primaryColor,
                },
                Checkbox: {
                    borderRadiusSM: 0,
                    colorPrimaryHover: 'white',
                    colorPrimaryBorder: 'var(--primary-color)',
                    colorPrimary: 'white',
                    colorBorder: 'var(--primary-color)',
                    colorWhite: 'var(--primary-color)',
                },
                Button: {
                    textHoverBg: 'transparent',
                    primaryShadow: 'none',
                    defaultBorderColor: 'var(--primary-color)',
                    defaultColor: 'rgba(0, 0, 0, 0.88)',
                    colorText: 'var(--primary-color)',
                    paddingInline: 16,
                    fontSizeLG: 14,
                    colorLink: 'black',
                    colorLinkHover: 'black',
                },
                Select: {
                    colorBorder: 'transparent',
                    colorPrimary: 'transparent',
                    colorPrimaryHover: 'transparent',
                    optionSelectedBg: '#ebebf0',
                    lineWidth: 0,
                },
                Card: {
                    colorBorderSecondary: 'transparent',
                    paddingLG: 8,
                },
                Modal: {
                    borderRadiusLG: 0,
                },
                Collapse: {
                    headerBg: 'white',
                    lineWidth: 0,
                    fontWeightStrong: 500,
                    headerPadding: 10,
                    contentBg: 'white',
                },
                Tabs: {
                    cardPadding: '0px 16px',
                },
                Input: {
                    lineWidth: 0,
                },
            },
        }

        if (apiConfig?.override_antd_theme) {
            const parsedConfigTheme = JSON.parse(apiConfig?.override_antd_theme) as ThemeConfig
            Object.keys(parsedConfigTheme).forEach((configKey) => {
                if (configKey === 'token') {
                    theme.token = { ...theme.token, ...parsedConfigTheme[configKey] }
                }

                if (configKey === 'components') {
                    const configComponentsTheme = parsedConfigTheme[configKey]

                    if (!configComponentsTheme) {
                        return
                    }

                    let componentKey: keyof typeof configComponentsTheme
                    for (componentKey in configComponentsTheme) {
                        if (!theme.components) {
                            continue
                        }

                        if (theme.components[componentKey]) {
                            theme.components[componentKey] = {
                                ...theme.components[componentKey],
                                ...configComponentsTheme[componentKey],
                            } as typeof theme.components

                            continue
                        }

                        theme.components[componentKey] = configComponentsTheme[
                            componentKey
                        ] as typeof theme.components
                    }
                }
            })
        }

        return theme
    }, [apiConfig, storeConfig])

    const { models, look, itemType } = useMemo(() => {
        // ---- Recuperation de l'item type ----
        let configItemType = undefined
        if (storeConfig.queryParams && storeConfig.queryParams.focus) {
            configItemType = storeConfig.queryParams.focus.toLowerCase()
        } else {
            for (const key in storeConfig.queryParams) {
                const match = key.match(/^([a-z]+)_garment_id$/)
                if (match) {
                    configItemType = match[1]
                    break
                }
            }
        }

        // ---- Recuperation des models et du look ----
        let dataModels = undefined,
            dataLook = undefined
        if (modelsResponse && storeConfig.config) {
            dataModels = modelsResponse.items
            dataLook = modelsResponse.look

            // ---- We fetch the value of nb_models and slice if we the response is bigger than the restriction ----
            const nbModels = parseInt(storeConfig.config['nb_models']) || 0
            if (nbModels > 0 && modelsResponse.items.length > nbModels) {
                dataModels = modelsResponse.items.slice(0, nbModels)
            }
        }

        return { models: dataModels, look: dataLook, itemType: configItemType }
    }, [modelsResponse, storeConfig])

    const openModal = () => {
        dispatch(changeModalVisible(true))
        const htmlElement = document.getElementsByTagName('html')[0]
        htmlOverflowRef.current = htmlElement.style.overflowY || null
        htmlElement.style.overflowY = 'hidden'
    }

    const closeModal = () => {
        dispatch(changeModalVisible(false))
    }

    // ---- Revert the html changes when we close the modal ----
    useEffect(() => {
        if (!modalVisible) {
            const htmlElement = document.getElementsByTagName('html')[0]
            htmlElement.style.overflowY = htmlOverflowRef.current || 'unset'
        }
    }, [modalVisible])

    useEffect(() => {
        // ---- Function called when we got a click event on the veesual CTA ----
        const handleButtonClick = (e: VeesualEvent) => {
            if (!e.detail.config) {
                return
            }

            const configClone: RawConfig = JSON.parse(JSON.stringify(e.detail.config))
            const customParams: RawConfig = {}

            // ---- We delete fields that we don't need in Query default params ----
            const queryParamkeys = [
                'api_key',
                'api_prefix',
                'nb_models',
                'custom_style',
                'cta_location',
                'cta_version',
                'primary_color',
            ]
            queryParamkeys.forEach((paramKey: string) => {
                customParams[paramKey] = configClone[paramKey]
                delete configClone[paramKey]
            })

            // ---- We dispatch the config to the store ----
            dispatch(addConfig(customParams))
            dispatch(addQueryParams(configClone))

            setClicked(true)
        }

        // ---- Function called to ask if initiated ----
        const handleInitEvent = () => {
            window.dispatchEvent(new CustomEvent('veesual_switch_model_initiated'))
        }

        window.addEventListener('veesual_enable_analytics', enableAnalytics)
        window.addEventListener('veesual_disable_analytics', disableAnalytics)
        window.addEventListener('veesual_switch_model_event', ((e: VeesualEvent) =>
            handleButtonClick(e)) as EventListener)
        window.addEventListener('veesual_switch_model_init', handleInitEvent)
        handleInitEvent()

        return () => {
            window.removeEventListener('veesual_enable_analytics', enableAnalytics)
            window.removeEventListener('veesual_switch_model_init', disableAnalytics)
            window.removeEventListener('veesual_switch_model_event', ((e: VeesualEvent) =>
                handleButtonClick(e)) as EventListener)
            window.removeEventListener('veesual_switch_model_init', handleInitEvent)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ---- Dispatch apiConfig when we get it ----
    useEffect(() => {
        if (!apiConfig) {
            return undefined
        }

        const cloneApiConfig = JSON.parse(JSON.stringify(apiConfig)) as CompanyProfile

        if (!cloneApiConfig.sm_nb_max_cards) {
            cloneApiConfig.sm_nb_max_cards = DEFAULT_NB_MAX_CAROUSEL_CARDS
        }

        dispatch(addApiConfig(cloneApiConfig))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiConfig])

    // ---- Language Handling ----
    useEffect(() => {
        // ---- Priority to test key ----
        if (storeQueryParams && ['cimode', 'label'].includes(storeQueryParams['locale'])) {
            i18n.changeLanguage('cimode')

            return undefined
        }

        // ---- Change language if locale exists in api config and we don't have a test key in queryparam locale ----
        if (apiConfig && apiConfig.locale) {
            i18n.changeLanguage(apiConfig.locale)

            return undefined
        }

        // ---- i18n config -----
        if (storeQueryParams && storeQueryParams['locale']) {
            i18n.changeLanguage(storeQueryParams['locale'])
        }

        return undefined
    }, [apiConfig, storeQueryParams])

    useEffect(() => {
        if (clicked) {
            setClicked(false)
            openModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clicked])

    return (
        <ConfigProvider theme={configTheme}>
            {storeConfig?.apiConfig?.override_sm_style && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: storeConfig.apiConfig.override_sm_style,
                    }}
                ></style>
            )}
            {isFetching || isApiConfigFetching ? (
                <div className='modal--loading-container'>
                    <Loader className='modal--loader' />
                </div>
            ) : (
                <Modal
                    className={`modal`}
                    destroyOnClose
                    open={modalVisible}
                    centered={true}
                    onCancel={() => closeModal()}
                    footer={null}
                    closable={false}
                    getContainer={`#${MODAL_ROOT_ID}`}
                    transitionName={
                        apiConfig?.internal === 'claudiepierlot-fr' ? '' : 'ant-modal-fade-in'
                    }
                    maskTransitionName=''
                >
                    <ModalSwitchModel
                        closeModal={closeModal}
                        models={models}
                        look={look}
                        itemType={itemType}
                        error={errorModels}
                    />
                </Modal>
            )}
        </ConfigProvider>
    )
}
