import { GarmentSize } from '../types/api-types'

/**
 * Returns the GarmentSize object from Garment according to the modelSizes
 * @param modelSizes The sizes array off the model
 * @param garmentSizes The GarmentSizes from the garment we want to find the size matching the model
 * @returns The matching GarmentSize, or undefined if we didn't find a match
 */
export const getModelMatchingGarmentSize = (modelSizes: string[], garmentSizes: GarmentSize[]) => {
    const regex = new RegExp(
        `(?:^|[^0-9a-zA-Z])(${modelSizes.toString().replaceAll(',', '|')})(?:$|[^0-9a-zA-Z])`
    )

    // ---- Check which sizes match the regex on modelSizes ----
    const foundGarmentSizes = garmentSizes.filter((garmentSize) => garmentSize.label.match(regex))

    // ---- Return undefined if nothing found ----
    if (foundGarmentSizes.length === 0) {
        return undefined
    }

    // ---- We want to prioritize sizes that are in stock or preorderable if there is multiple match ----
    const inStockGarmentSizes = foundGarmentSizes.filter((size) => size.in_stock || size.preorder)

    // ---- Return the first garment that matched only on label if no one is in stock ----
    if (inStockGarmentSizes.length === 0) {
        return foundGarmentSizes[0]
    }

    // ---- Return the first that is in stock ----
    return inStockGarmentSizes[0]
}

export const isGarmentSizePreorder = (value: string, optionSizes: GarmentSize[]) => {
    if (value) {
        for (const optionSize of optionSizes) {
            if (optionSize.value == value) {
                return optionSize.preorder === true
            }
        }
    }

    let hasStock = false
    let hasPreorder = false
    for (const optionSize of optionSizes) {
        if (optionSize.in_stock !== false) {
            hasStock = true
        }
        if (optionSize.preorder === true) {
            hasPreorder = true
        }
    }

    return hasPreorder && !hasStock
}

export const getGarmentSizeLabel = (value: string, optionSizes: GarmentSize[]) => {
    for (const optionSize of optionSizes) {
        if (optionSize.value == value) {
            return optionSize.label
        }
    }
    return null
}
