import { Col, Row, Spin } from 'antd'
import React, { CSSProperties } from 'react'
import { customSpinner as customEileenFisherSpinner } from '../assets/eileen_spinner'
import { useAppSelector } from '../services/store/store'

export default function Loader(props: {
    size?: 'default' | 'small' | 'large'
    ignoreCustom?: boolean
    className?: string
    style?: CSSProperties
}) {
    const internal = useAppSelector((state) => state.config.apiConfig?.internal)
    if (!props.ignoreCustom) {
        if (
            internal?.match(/^eileenfisher/) ||
            window.location.hostname.endsWith('eileenfisher.com')
        ) {
            return customEileenFisherSpinner
        }
    }

    return (
        <Row
            justify='space-around'
            align='middle'
            className={`custom-loader ${props.className || ''}`}
            style={props.style}
        >
            <Col>
                <Spin size={props.size || 'large'} />
            </Col>
        </Row>
    )
}
